import { Box, Button, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import { ReactComponent as RefreshIcon } from "src/assets/icon/refresh-icon.svg";
import TableComponent from "src/components/custom-table";
import { useMemo, useState, useEffect } from "react";
import { DownloadIcon } from "@chakra-ui/icons";
import moment from "moment";

import { useGetListLocationQuery } from "src/hook/location/location";
import {
  ItemInterface,
  SelectType,
  SessionInterface,
  SESSION_STATUS,
} from "./session.type";
import {
  useGetListChargeStationQuery,
  useLazyGetAllChargeStationByLocationQuery,
} from "src/hook/charge-station/charge-station";
import { useLazyGetListConnectorByChargeStationQuery } from "src/hook/connector/connector";
import {
  downloadSessionCsv,
  useLazyGetListSessionQuery,
} from "src/hook/session/session";
import {
  convertSeccondToHour,
  formatDateTime,
  getToastAttribute,
  PaginationType,
} from "src/constants";
import { ReactComponent as CopyIcon } from "src/assets/icon/copy-icon.svg";
import { downloadCsv } from "src/constants/download-csv";
import { useAppSelector } from "src/redux";
import { MultiSelectComponent } from "src/components/multi-select/multi-select.component";
import { RangePicker } from "src/components/daterange-picker";
import { getCurrentTimeZoneOffset } from "src/constants/timezone";

const today = new Date();
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

export const SessionComponent = () => {
  const [pageIndex, setPageIndex] = useState<PaginationType>({
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageCount: 0,
  });
  const [startDay, setStartDay] = useState(
    moment(sevenDaysAgo).format("YYYY-MM-DD")
  );
  const [stopDay, setStopDay] = useState(moment(today).format("YYYY-MM-DD"));
  const [listLocation, setListLocation] = useState<SelectType[]>([]);
  const [listCS, setListCS] = useState<SelectType[]>([]);
  const [listConnector, setListConnector] = useState<SelectType[]>([]);
  const [isFirstTimeCallApi, setIsFirstTime] = useState(true);
  const [listSession, setListSession] = useState<SessionInterface[]>([]);
  const [isLoadingState, setIsLoading] = useState(false);
  const [isExportLoading, setExportLoading] = useState(false);
  const [filterParams, setFilterParams] = useState("");

  const [numberOfLocation, setNumberOfLocation] = useState(0);
  const [numberOfCS, setNumberOfCS] = useState(0);
  const [numberOfConnector, setNumberOfConnector] = useState(0);

  const toast = useToast();
  const copyTextNoti = () => {
    toast({
      title: "Copy ID Successfully.",
      status: "info",
      duration: 1500,
      isClosable: true,
      position: "top",
    });
  };
  const sessionId = sessionStorage.getItem("sessionId") || "";
  const filterCompanies = useAppSelector(
    (state) => state?.headerFilter?.companyId
  );

  const { data: listLocationData, isFetching: isLocationFetching } =
    useGetListLocationQuery({
      page: "full",
      take: "full",
      companyId: filterParams,
    });
  const [getListCS, { isFetching: isCSFetching }] =
    useLazyGetAllChargeStationByLocationQuery();
  const { data: listChargeStation } = useGetListChargeStationQuery({
    page: "full",
    take: "full",
    companyId: filterParams,
  });
  const [getListConnector, { isFetching: isConnectorFetching }] =
    useLazyGetListConnectorByChargeStationQuery();
  const [getListSession, { isFetching }] = useLazyGetListSessionQuery();

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(
        filterCompanies.map((companyId) => `companyId[]=${companyId}`).join("&")
      );
    } else {
      setFilterParams("");
    }
  }, [filterCompanies]);

  useEffect(() => {
    const list = listLocationData?.data?.data?.map((item: ItemInterface) => ({
      value: item?.id,
      label: item?.name,
      check: false,
    }));
    setListLocation(() => list);
  }, [listLocationData]);

  useEffect(() => {
    setIsLoading(() => true);
    if (filterParams && listLocation?.every((item) => !item.check)) {
      const list = listChargeStation?.data?.data?.map((item: ItemInterface) => {
        const temp = listCS?.find((child) => child?.value === item?.id);
        return {
          value: item?.id,
          label: item?.name,
          check: temp?.check ?? false,
        };
      });
      setListCS(() => list);
    } else {
      getListCS(listLocation)
        .unwrap()
        .then((data) => {
          const list = data?.data?.map((item: ItemInterface) => {
            const temp = listCS?.find((child) => child?.value === item?.id);
            return {
              value: item?.id,
              label: item?.name,
              check: temp?.check ?? false,
            };
          });
          setListCS(() => list);
        });
    }
  }, [listLocation]);

  const handleGetListSession = (page: number) => {
    setIsLoading(() => true);
    const timezoneOffset = getCurrentTimeZoneOffset().toString();
    setNumberOfLocation(listLocation.filter((item) => item?.check).length);
    setNumberOfCS(listCS.filter((item) => item?.check).length);
    setNumberOfConnector(listConnector.filter((item) => item?.check).length);

    getListSession({
      listConnector: listConnector,
      listLocation: listLocation,
      listCS: listCS,
      page: page,
      companyIdList: filterCompanies,
      startDay: startDay,
      stopDay: stopDay,
      timezone: timezoneOffset,
    })
      .unwrap()
      .then((data) => {
        setListSession(() => data?.data?.data || []);
        const panigation = data?.data?.meta;
        if (panigation) {
          setPageIndex({
            page: panigation?.page,
            hasNextPage: panigation?.hasNextPage,
            hasPreviousPage: panigation?.hasPreviousPage,
            pageCount: panigation?.pageCount,
          });
        }
        setIsLoading(() => false);
      })
      .catch(() => {
        setIsLoading(() => false);
      });
  };

  useEffect(() => {
    setIsLoading(() => true);
    if (!listCS?.length) {
      setListConnector(() => []);
    } else {
      getListConnector(listCS)
        .unwrap()
        .then((data) => {
          const list = data?.data?.map((item: ItemInterface) => {
            const temp = listConnector?.find(
              (child) => child?.value === item?.id
            );
            if (isFirstTimeCallApi && item?.id === sessionId) {
              sessionStorage.removeItem("sessionId");
              return {
                value: item?.id,
                label: item?.id,
                check: true,
              };
            }
            return {
              value: item?.id,
              label: item?.id,
              check: temp?.check ?? false,
            };
          });
          setListConnector(() => list);
          setPageIndex((pre) => ({
            ...pre,
            page: 1,
          }));
          setIsFirstTime(() => false);
        });
    }
  }, [listCS]);

  useEffect(() => {
    if (!isFirstTimeCallApi) {
      handleGetListSession(1);
    }
  }, [listConnector, filterCompanies, startDay, stopDay, pageIndex?.page]);

  useEffect(() => {
    if (!isFirstTimeCallApi && pageIndex?.page !== 1) {
      handleGetListSession(pageIndex?.page);
    }
  }, [pageIndex?.page]);

  const handleExport = async () => {
    setExportLoading(true);
    const timezoneOffset = getCurrentTimeZoneOffset().toString();
    await downloadSessionCsv({
      listConnector: listConnector,
      listCS: listCS,
      companyIdList: filterCompanies,
      listLocation: listLocation,
      startDay: startDay,
      stopDay: stopDay,
      timezone: timezoneOffset,
    })
      .then((data) => {
        downloadCsv(data, "session");
      })
      .catch(() => {
        toast(
          getToastAttribute({
            title: "Export file",
            dis: "Export file failed",
            sts: "error",
          })
        );
      });
    setExportLoading(false);
  };

  const convertAttribute = (
    item: string,
    isCanCopy = false,
    isUnderLine = false,
    isColorBlue = false,
    fontFamily = "Avenir Medium"
  ) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Tooltip label={item}>
          <Text
            w={"fit-content"}
            fontSize={"18px"}
            fontFamily={fontFamily}
            color={isColorBlue ? "#0A47BA" : "#020D21"}
            mr={"6px"}
            whiteSpace={"nowrap"}
            maxW={"120px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            lineHeight={"20px"}
            textDecoration={isUnderLine ? "underline" : ""}
          >
            {item}
          </Text>
        </Tooltip>
        {isCanCopy && item?.length && (
          <Box
            cursor={"pointer"}
            onClick={() => {
              navigator.clipboard.writeText(item);
              copyTextNoti();
            }}
          >
            <CopyIcon stroke="#0a47ba" width={"20px"} height={"20px"} />
          </Box>
        )}
      </Box>
    );
  };

  const convertTime = (value: string) => {
    const date = value?.trim()?.length === 0 ? "" : formatDateTime(value);
    return (
      <Text whiteSpace={"nowrap"} fontFamily={"Avenir Book"}>
        {date}
      </Text>
    );
  };

  const convertSpecialAttribute = (item: number, unit: string, fixed = 2) => {
    return (
      <Text
        w={"fit-content"}
        fontSize={"16px"}
        fontFamily={"Avenir Book"}
        color={"#020D21"}
        mr={"6px"}
        whiteSpace={"nowrap"}
        maxW={"150px"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        lineHeight={"20px"}
      >
        {item === null || typeof item === undefined
          ? ""
          : item.toFixed(fixed) + ` ${unit}`}
      </Text>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Connector ID",
        accessor: (item: SessionInterface) =>
          convertAttribute(item?.connector?.id, true, false, true),
      },
      {
        Header: "Status",
        accessor: (item: SessionInterface) =>
          convertAttribute(item?.status, false, false, true),
      },
      {
        Header: "Session ID",
        accessor: (item: SessionInterface) =>
          convertAttribute(item?.id, true, true, false, "Avenir Book"),
      },
      {
        Header: "Start time",
        accessor: (item: SessionInterface) => convertTime(item?.createdAt),
      },
      {
        Header: "End time",
        accessor: (item: SessionInterface) =>
          convertTime(
            item?.status === SESSION_STATUS.STARTED ? "" : item?.updatedAt
          ),
      },
      {
        Header: "Location",
        accessor: (item: SessionInterface) =>
          convertAttribute(
            item?.chargeStation?.location?.name,
            false,
            true,
            true,
            "Avenir Heavy"
          ),
      },
      {
        Header: "Chargestation",
        accessor: (item: SessionInterface) =>
          convertAttribute(
            item?.chargeStation?.name,
            false,
            true,
            true,
            "Avenir Heavy"
          ),
      },
      {
        Header: "Duration (hr)",
        accessor: (item: SessionInterface) =>
          convertSeccondToHour(item?.duration),
      },
      {
        Header: "Consumption",
        accessor: (item: SessionInterface) =>
          convertSpecialAttribute(item?.consumption, "kWh", 3),
      },
      //TODO: uncomment later when integrate API
      // {
      //   Header: "Cost",
      //   accessor: (item: SessionInterface) =>
      //     convertSpecialAttribute(item?.cost, "USD", 2),
      // },
    ],
    []
  );

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#ffffff"}
        overflow={"hidden"}
        p={"74px 40px 74px 40px"}
      >
        <Flex
          mb={"15px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          wrap={"wrap"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Sessions
          </Text>
          <Flex alignItems={"center"} justifyContent={"flex-end"} w={"60%"}>
            <Button
              variant={"solid"}
              mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              onClick={() => window.location.reload()}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
            >
              <RefreshIcon stroke="#FFFFFF" />
              <Text ml={"16px"}>Refresh</Text>
            </Button>

            <Button
              variant={"outline"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                opacity: "0.8",
              }}
              onClick={handleExport}
              isLoading={isExportLoading}
              disabled={isExportLoading}
            >
              <DownloadIcon />
              <Text ml={"16px"}>Export</Text>
            </Button>
          </Flex>
        </Flex>
        <Flex mb={"40px"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
          <MultiSelectComponent
            list={listLocation}
            setList={setListLocation}
            title={
              numberOfLocation
                ? numberOfLocation > 1
                  ? `${numberOfLocation} locations selected`
                  : `${numberOfLocation} location selected`
                : "All locations"
            }
            isFetching={isLocationFetching}
          />
          <MultiSelectComponent
            list={listCS}
            title={
              numberOfCS
                ? numberOfCS > 1
                  ? `${numberOfCS} charge stations selected`
                  : `${numberOfCS} charge station selected`
                : "All charge stations"
            }
            isFetching={isCSFetching}
            setList={setListCS}
          />
          <MultiSelectComponent
            list={listConnector}
            title={
              numberOfConnector
                ? numberOfConnector > 1
                  ? `${numberOfConnector} connectors selected`
                  : `${numberOfConnector} connector selected`
                : "All connectors"
            }
            isFetching={isConnectorFetching}
            setList={setListConnector}
          />
          <RangePicker
            setStartDay={setStartDay}
            setStopDay={setStopDay}
            isDefault={true}
          />
        </Flex>
        <TableComponent
          isLoading={isFetching || isFirstTimeCallApi || isLoadingState}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          columns={columns as any}
          data={listSession}
          name={"location"}
        />
      </Box>
    </Box>
  );
};
